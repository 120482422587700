import { SEOConfig } from '@/types/head';
import Head from 'next/head';
import { memo } from 'react';

interface CustomHeadProps {
  seo: ICB.SEOConfig,
  share: ICB.ShareConfig,
  children?: React.ReactNode,
}

export default memo(function CustomHead(props: CustomHeadProps) {

  const { seo, share, children } = props

  const facebook = {
    title: share.title,
    description: share.description,
    image: share.image,
    url: share.url || global.location?.href,
  }

  const def = {
    title: share.title,
    description: share.description,
    image: share.image,
    url: share.url,
  }

  const twitter = {
    title: share.title,
    description: share.description,
    image: share.image,
    url: share.url,
  }

  return (
    <Head>
      <title>{seo.title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"></meta>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no, viewport-fit=cover"></meta>
      <meta name="keywords" content={seo.keywords} />
      <meta name="description" content={seo.description} />
      <meta name="renderer" content="webkit" />
      <link rel="icon" type="image/x-icon" href="/favicon.ico" />

      {def ? (
        <>
          <meta property="type" content="article" />
          <meta property="title" content={seo.title} />
          <meta property="description" content={seo.description} />
          <meta property="image" content={def.image} />
          <meta itemProp="image" content={def.image} />
        </>
      ) : ''}

      {facebook ? (
        <>
          <meta property="og:type" content="website" />
          <meta property="og:title" content={facebook.title} />
          <meta property="og:description" content={facebook.description} />
          <meta property="og:image" content={facebook.image} />
          <meta property="og:url" content={facebook.url}></meta>
        </>
      ) : ''}

      {twitter ? (
        <>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={twitter.title} />
          <meta name="twitter:description" content={twitter.description} />
          <meta name="twitter:image:alt" content="Doodles" />
          <meta name="twitter:image" content={twitter.image} />
        </>
      ) : ''}

      {children}
    </Head>
  );
})
