import Script from 'next/script'
import useTrack from '@/hooks/useTrack'
import useChannel from '@/hooks/useChannel'
import { useEffect, useState } from 'react'
import styles from '@/styles/page-base.layout.module.scss'
// import Head from '@/components/headv2';
import { THead } from '@/types/head'
import useOpenInstall from '@/hooks/useOpenInstall'
import PageLoad from '@/components/page-load'

interface PageBaseLayoutProps {
  children: React.ReactNode,
  pagename: string,
}

export default function PageBaseLayout({ children, pagename }: PageBaseLayoutProps) {

  // !!!
  // 注意，请尽量不要在layout里做和页面业务UI相关的事情，这里只是公共逻辑的承载。
  // TODO: 后续将share和seo的header逻辑移动到这里。当前暂时分开，以确保重构前后的逻辑继承

  // 这里为每个页面埋入PV的打点。
  const track = useTrack()
  const ch = useChannel()
  const env = process.env.NEXT_PUBLIC_ENV

  // 这里增加PV打印，同时增加渠道信息
  useEffect(() => {
    if (track && ch) {
      track.pageview(pagename, { channel: ch?.channel, shareCode: ch?.shareCode })
    }
  }, [track, ch])

  // 初始化openinstall，用于上报渠道PV和share
  useOpenInstall()

  return <>
    {/* 页面REM */}
    {/* <Script src='https://static.smartdeer.com/global/common/resource/flexible.js' strategy="beforeInteractive"></Script> */}

    {/* openinstall */}
    <Script type="text/javascript" src="https://web.cdn.openinstall.io/openinstall.js" strategy="beforeInteractive"></Script>

    {/* 页面header配置, 包含twitter和facebook分享的配置信息 */}
    {/* { head ? <Head head={head} /> : <></> } */}


    {/* load 动画 */}
    <PageLoad />

    {/* 页面内容 */}
    <div className={styles['page-container']}>
      <main className={styles['page-main']}>{children}</main>
    </div>
  </>
} 