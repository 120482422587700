import { useEffect, useState, memo } from 'react'
import styles from './page-load.module.scss'

import SvgLoading from "@/components/subject/global-jobs/svg-loading"

export default memo(function PageLoad() {
  // load 动画
  const [ showLoad, setShowLoad ] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setShowLoad(false)
    }, 700)
  }, [])


  return <>
    {/* load 动画 */}
    {showLoad ? (
      <div className={styles['load']}>
        <SvgLoading size={'1rem'}></SvgLoading>
      </div>
    ) : '' }
  </>
})